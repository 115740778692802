import { GameCardProps } from '../props';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import GameCardTooltip from './Tooltip';
import { Game } from '@/models/game';
import React from 'react';
import ImageDimensionLoader from '@/components/core/ImageDimensionLoader';
import { ToolTip } from '@package/components';
import { useGameCard } from '@/hooks/useGameCard';
import GameCardV1Skeleton from './Skeleton';

export default function GameCardV1({
  game,
  priority,
  isLoading,
  showTotalPaid,
}: GameCardProps) {
  const {
    imageMapping,
    onCardClick,
    onProviderClick,
    redirectUrl,
    formattedDailyPayout,
  } = useGameCard(game);

  if (isLoading) {
    return <GameCardV1Skeleton showTotalPaid={showTotalPaid} />;
  }

  return (
    <Link href={redirectUrl} className={styles.container} onClick={onCardClick}>
      <div className={styles['container__image-container']}>
        <div className={styles['container__image-container__info']}>
          <div className={styles['container__image-container__info__title']}>
            {game?.name}
          </div>
          <div
            className={styles['container__image-container__info__play__button']}
          >
            <i className="fa-solid fa-play" />
          </div>
          <div />
        </div>

        <ImageDimensionLoader
          imageMapping={imageMapping}
          imagePreview={game?.previewImage}
          loading={priority ? 'eager' : 'lazy'}
          className={styles['container__image-container__image']}
        />
      </div>
      <div className={styles.container__content}>
        <div className={styles.container__content__row}>
          <div className={styles.container__content__row__title}>
            {game?.name}
          </div>
          <div className={styles['container__hide-mobile']}>
            <ToolTip titleComponent={<GameCardTooltip game={game as Game} />}>
              <i
                className={`${styles.container__content__row__icon} fa-duotone fa-circle-info`}
              />
            </ToolTip>
          </div>
        </div>

        {showTotalPaid ? (
          <div className={styles.container__content__amount}>
            <div className={styles.container__content__amount__coin__amount}>
              {formattedDailyPayout}
            </div>
          </div>
        ) : (
          <div className={styles['container__hide-mobile']}>
            <div
              className={styles.container__content__description}
              onClick={onProviderClick}
            >
              {game?.provider?.name}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}
