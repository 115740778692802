import { GameProviderId } from '@/enums/gameProviderId';
import { RootState } from '@/lib/store';
import { toggleSearch } from '@/lib/store/casino/actions';
import { Game } from '@/models/game';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { CoinID, Device } from '@package/enums';
import { Nullable } from '@package/interfaces';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseGameCardResponse } from './props';
import { WalletService } from '@package/services';

export function useGameCard(game: Nullable<Game>): UseGameCardResponse {
  const dispatch = useDispatch();
  const openSearch = useSelector((state: RootState) => state.casino.openSearch);
  const { query, push } = useRouter();
  const tag: GameTagFilter | undefined = query.tag as GameTagFilter;
  const BASE_URL: string = `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/games`;
  const imageMapping: Map<Device, string> = useMemo(
    () =>
      new Map<Device, string>([
        [Device.Desktop, `${BASE_URL}/300x300${game?.image}`],
        [Device.Tablet, `${BASE_URL}/150x150${game?.image}`],
      ]),
    [game, BASE_URL],
  );
  const redirectUrl: string =
    game?.provider?.id === GameProviderId.Originals
      ? `/games/tag/originals/${game?.slug}`
      : `/games/tag/${tag ?? GameTagFilter.Slots}/${game?.slug}`;

  const formattedDailyPayout: string = WalletService.maskCurrency({
    value: game?.dailyPayout ?? 0,
    coinId: CoinID.USD,
  });

  function onCardClick() {
    if (!openSearch) {
      return;
    }

    dispatch(toggleSearch());
  }

  function onProviderClick(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    push(`/games/providers/${game?.provider?.slug ?? 'originals'}`);
    onCardClick();
  }

  return {
    redirectUrl,
    onCardClick,
    imageMapping,
    onProviderClick,
    formattedDailyPayout,
  };
}
